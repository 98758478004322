import React from 'react';

const SeoNya = () => {
  return (
      <div className="test">
        <title>
        HSP - Solusi Internet Kantor & Enterprise Terbaik 
        </title>
        <meta
          name='author'
          content="PT. Yetoya Solusi Indonesia | HSP') }}"
        />
        <meta name='type' content='Company' />
        <meta name='url' content='https://hsp.net.id/' />
        <meta name='title' content='Solusi Internet Kantor & Enterprise Terbaik' />
        <meta
          name='keywords'
          content='Internet Kantor, Internet Enterprise, Koneksi Internet, Jaringan Internet, Solusi Internet, Bisnis, Produktivitas, HSP Net'
        />
        <meta
          name='description'
          content='Solusi Internet Kantor & Enterprise Terbaik - HSP Net. Tingkatkan konektivitas dan produktivitas bisnis Anda dengan koneksi internet berkecepatan tinggi, stabil, dan aman.'
        />
      </div>
  );
};

export default SeoNya;
