import React, { Suspense, lazy } from 'react';
import { Image } from 'react-bootstrap';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import ScrollToTop from './pondasi/ScrollToTop.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import './sementara.css';
import './stel.css';
import './respo.css';
import './produk.css';
import { Navigate } from 'react-router-dom';
import preloadHsp from './gambar/pokok/loaderhsp.gif';
import SeoNya from './laman/beranda/SeoNya';

const Beranda = lazy(() => import('./laman/beranda/Beranda.js'));
const Tentang = lazy(() => import('./laman/tentang/Tentang.js'));
const Produk = lazy(() => import('./laman/produk/Produk.js'));
const CoverArea = lazy(() => import('./laman/coverarea/CoverArea.js'));
const Kontak = lazy(() => import('./laman/kontak/Kontak.js'));
const Snk = lazy(() => import('./laman/snk/Snk.js'));
const KontakDinamis = lazy(() => import('./laman/kontak/KontakDinamis.js'));
const Berita = lazy(() => import('./laman/berita/Berita.js'));
const DynamicBerita = lazy(() => import('./laman/berita/DynamicBerita.js'));
const KategoriDynamis = lazy(() => import('./laman/produk/KategoriDynamis.js'));
const ProdukDinamis = lazy(() => import('./laman/produk/ProdukDinamis.js'));
const JobList = lazy(() => import('./laman/joblist/JobList.js'));
const Artikel = lazy(() => import('./laman/artikel/Artikel.js'));
const DynamicArtikel = lazy(() => import('./laman/artikel/DynamicArtikel.js'));
////////////////////////
// Import Inggris EN //
//////////////////////
const Home = lazy(() => import('./en/page/home/Home.js'));
const About = lazy(() => import('./en/page/about/About.js'));
const Product = lazy(() => import('./en/page/product/Product.js'));
const Coverage = lazy(() => import('./en/page/coverage/Coverage.js'));
const SnkEn = lazy(() => import('./en/page/snk/Snk.js'));
const News = lazy(() => import('./en/page/news/News.js'));
const DynamicNew = lazy(() => import('./en/page/news/DynamicNew.js'));
const Article = lazy(() => import('./en/page/article/Article.js'));
const DynamicArticle = lazy(() => import('./en/page/article/DynamicArticle.js'));
const DynamicCatPro = lazy(() => import('./en/page/product/DynamicCatPro.js'));
const DynamicProduct = lazy(() =>
  import('./en/page/product/DynamicProduct.js')
);

const root = ReactDOM.createRoot(document.getElementById('hspRoot'));
root.render(
  <BrowserRouter>
    <ScrollToTop />
    <SeoNya/>
    <Suspense
      fallback={
        <center>
          <Image
            className='preloaderHsp'
            sizes='md'
            src={preloadHsp}
            alt='loader HSP'
          />
        </center>
      }
    >
      <Routes>
        <Route exact path={'/'} element={<Beranda />} />
        <Route path={'/tentang'} element={<Tentang />} />
        <Route path={'/cakupan-area'} element={<CoverArea />} />
        <Route path={'/kontak'} element={<Kontak />} />
        <Route path={'/snk'} element={<Snk />} />
        <Route path={'/kontak/:urlProd'} element={<KontakDinamis />} />
        <Route exact path={'/berita'} element={<Berita />}></Route>
        <Route path={'/berita/:idBerita'} element={<DynamicBerita />} />
        <Route path={'/produk/'} element={<Produk />} />
        <Route path={'/produk/:katUrl'} element={<KategoriDynamis />} />
        <Route path={'/produk/:katUrl/:prodUrl'} element={<ProdukDinamis />} />
        <Route path={'/karir/joblist'} element={<JobList />} />
        <Route exact path={'/artikel'} element={<Artikel />}></Route>
        <Route path={'/artikel/:idArtikel'} element={<DynamicArtikel />} />
        {/*////////////////////
        // Route Inggris EN //
        /////////////////////*/}
        <Route path={'/en/'} element={<Home />} />
        <Route path={'/en/tentang'} element={<About />} />
        <Route path={'/en/snk'} element={<SnkEn />} />
        <Route path={'/en/cakupan-area'} element={<Coverage />} />
        <Route
          path={'/en/kontak'}
          element={<Navigate replace to='/kontak' />}
        />
        <Route exact path={'/en/berita'} element={<News />} />
        <Route path={'/en/berita/:idNews'} element={<DynamicNew />} />
        <Route exact path={'/en/artikel'} element={<Article />} />
        <Route path={'/en/artikel/:idArticle'} element={<DynamicArticle />} />
        <Route path={'/en/produk'} element={<Product />} />
        <Route path={'/en/produk/:katUrl'} element={<DynamicCatPro />} />
        <Route
          path={'/en/produk/:katUrl/:prodUrl'}
          element={<DynamicProduct />}
        />
      </Routes>
    </Suspense>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
